/************
 * Developed by : Shiva Software Solutions
 * Date : 28-06-2021
 * Descriptions : To call Sigin for gudmed operators and admin design page
 * Dependicies : To use react-router-dom,@material-ui/core,formik
 ************/
export const authRole = {
  admin: ['admin'],
  user: ['Doctor', 'Premium Doctor'],
  adminRoles: [1],
  dashboardRoles: [1, 2],
  premiumRole: ['Premium Doctor'],
  appointmentRoles: [1],
  mrdRole: [3],
  mrdName: ['MRD Doctor'],
  dischargeRole: [true],
};

export const userRole = {
  user: [
    {
      roleId: 1,
      roleName: 'Doctor',
    },
    {
      roleId: 2,
      roleName: 'Premium Doctor',
    },
  ],
};

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: 'https://via.placeholder.com/150',
};
export const initialUrl = '/dashboard'; // this url will open after login
export const initUrlProfile = '/my-profile'; // this url will open after login
export const MobileRegExp = /^[0-9\b]+$/;
export const digitRegExp = /^[0-9]*\.?[0-9]*$/;
export const allowDouble = /^[0-9\b.]+$/;
export const nameRegExp = /[^A-Za-z\s]/;
export const addressRegExp = /^[A-Za-z\s][0-9]*\.?[0-9]*$/;
export const AvoidSpecialCharacterRegExp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~¬£]/;
export const AvoidSpecialCharacterMailRegExp = /[`!#$%^&*()+\=\[\]{};':"\\|,<>\/?~¬£]/;
export const AvoidSpecialCharacterDrname = /[`!@#$%^&*_+\-=\[\]{};':"\\|,<>\/?~¬£]/;
export const AvoidSpecialCharacterAddressRegExp = /[`!@#$%^*+\=\[\]{};:"\\|<>\?~¬£]/;
export const AvoidSearchpatientRegExp = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\?~¬£]/;
export const MailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const AvoidSpecialCharacterhospitalRegExp = /[`!@#$%^*_+\=\[\]{};:"\\|<>\?~¬£]/;
//  export const nameRegExpdot = /[^A-Za-z\s.]/;
export const nameRegExpdot = /^\p{Lu}\p{Ll}+( \p{Lu}\p{Ll}+)*$/;
// /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const passwordRegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;

export const genderMaster = [
  {
    genderCode: 1,
    gender: 'Male',
  },
  {genderCode: 2, gender: 'Female'},
  {genderCode: 3, gender: 'Transgender'},
];

export const tempceltofaren = [
  {cell: 35.1, faren: 95.2},
  {cell: 35.2, faren: 95.4},
  {cell: 35.3, faren: 95.6},
  {cell: 35.4, faren: 95.8},
  {cell: 35.6, faren: 96.0},
  {cell: 35.7, faren: 96.2},
  {cell: 35.8, faren: 96.4},
  {cell: 35.9, faren: 96.6},
  {cell: 36.0, faren: 96.8},
  {cell: 36.1, faren: 97.0},
  {cell: 36.2, faren: 97.2},
  {cell: 36.3, faren: 97.4},
  {cell: 36.4, faren: 97.6},
  {cell: 36.5, faren: 97.7},
  {cell: 36.6, faren: 97.8},
  {cell: 36.7, faren: 98.0},
  {cell: 36.8, faren: 98.2},
  {cell: 36.9, faren: 98.4},
  {cell: 37.0, faren: 98.6},
  {cell: 37.1, faren: 98.8},
  {cell: 37.3, faren: 99.2},
  {cell: 37.4, faren: 99.4},
  {cell: 37.6, faren: 99.6},
  {cell: 37.7, faren: 99.8},
  {cell: 37.8, faren: 100.0},
  {cell: 37.9, faren: 100.2},
  {cell: 38.0, faren: 100.4},
  {cell: 38.1, faren: 100.6},
  {cell: 38.2, faren: 100.8},
  {cell: 38.3, faren: 101.0},
  {cell: 38.4, faren: 101.2},
  {cell: 38.6, faren: 101.4},
  {cell: 38.7, faren: 101.6},
  {cell: 38.8, faren: 101.8},
  {cell: 38.9, faren: 102.0},
  {cell: 39.0, faren: 102.2},
  {cell: 39.1, faren: 102.4},
  {cell: 39.2, faren: 102.6},
  {cell: 39.3, faren: 102.8},
  {cell: 39.4, faren: 103.0},
  {cell: 39.7, faren: 103.4},
  {cell: 39.8, faren: 103.6},
  {cell: 39.9, faren: 103.8},
  {cell: 40.0, faren: 104.0},
  {cell: 40.1, faren: 104.2},
  {cell: 40.2, faren: 104.4},
  {cell: 40.3, faren: 104.6},
  {cell: 40.4, faren: 104.8},
  {cell: 40.6, faren: 105.0},
  {cell: 40.7, faren: 105.2},
  {cell: 40.8, faren: 105.4},
  {cell: 40.9, faren: 105.6},
  {cell: 41.0, faren: 105.8},
  {cell: 41.1, faren: 106.0},
  {cell: 41.2, faren: 106.2},
  {cell: 41.3, faren: 106.4},
  {cell: 41.4, faren: 106.6},
  {cell: 41.6, faren: 106.8},
];

export const status = {
  pending: 0,
  inprogress: 1,
  rejected: 2,
  completed: 3,
  sendreview: 4,
  returnBack: 5,
};
export const version = '1.0.4';

export const all_prescription_Type = [
  {
    Id: -1,
    type: 'All',
    dispId: -1,
  },
  {
    Id: 1,
    type: 'IPD',
    dispId: 1,
  },
  {
    Id: 2,
    type: 'OPD',
    dispId: 2,
  },
];

export const default_prescription_Type = {
  Id: -1,
  type: 'All',
  dispId: -1,
};

export const appointmentTimeSlots = [
  {value: '00:00', id: 1, disabled: false},
  {value: '00:15', id: 2, disabled: false},
  {value: '00:30', id: 3, disabled: false},
  {value: '00:45', id: 4, disabled: false},
  {value: '01:00', id: 5, disabled: false},
  {value: '01:15', id: 6, disabled: false},
  {value: '01:30', id: 7, disabled: false},
  {value: '01:45', id: 8, disabled: false},
  {value: '02:00', id: 9, disabled: false},
  {value: '02:15', id: 10, disabled: false},
  {value: '02:30', id: 11, disabled: false},
  {value: '02:45', id: 12, disabled: false},
  {value: '03:00', id: 13, disabled: false},
  {value: '03:15', id: 14, disabled: false},
  {value: '03:30', id: 15, disabled: false},
  {value: '03:45', id: 16, disabled: false},
  {value: '04:00', id: 17, disabled: false},
  {value: '04:15', id: 18, disabled: false},
  {value: '04:30', id: 19, disabled: false},
  {value: '04:45', id: 20, disabled: false},
  {value: '05:00', id: 21, disabled: false},
  {value: '05:15', id: 22, disabled: false},
  {value: '05:30', id: 23, disabled: false},
  {value: '05:45', id: 24, disabled: false},
  {value: '06:00', id: 25, disabled: false},
  {value: '06:15', id: 26, disabled: false},
  {value: '06:30', id: 27, disabled: false},
  {value: '06:45', id: 28, disabled: false},
  {value: '07:00', id: 29, disabled: false},
  {value: '07:15', id: 30, disabled: false},
  {value: '07:30', id: 31, disabled: false},
  {value: '07:45', id: 32, disabled: false},
  {value: '08:00', id: 33, disabled: false},
  {value: '08:15', id: 34, disabled: false},
  {value: '08:30', id: 35, disabled: false},
  {value: '08:45', id: 36, disabled: false},
  {value: '09:00', id: 37, disabled: false},
  {value: '09:15', id: 38, disabled: false},
  {value: '09:30', id: 39, disabled: false},
  {value: '09:45', id: 40, disabled: false},
  {value: '10:00', id: 41, disabled: false},
  {value: '10:15', id: 42, disabled: false},
  {value: '10:30', id: 43, disabled: false},
  {value: '10:45', id: 44, disabled: false},
  {value: '11:00', id: 45, disabled: false},
  {value: '11:15', id: 46, disabled: false},
  {value: '11:30', id: 47, disabled: false},
  {value: '11:45', id: 48, disabled: false},
  {value: '12:00', id: 49, disabled: false},
  {value: '12:15', id: 50, disabled: false},
  {value: '12:30', id: 51, disabled: false},
  {value: '12:45', id: 52, disabled: false},
  {value: '13:00', id: 53, disabled: false},
  {value: '13:15', id: 54, disabled: false},
  {value: '13:30', id: 55, disabled: false},
  {value: '13:45', id: 56, disabled: false},
  {value: '14:00', id: 57, disabled: false},
  {value: '14:15', id: 58, disabled: false},
  {value: '14:30', id: 59, disabled: false},
  {value: '14:45', id: 60, disabled: false},
  {value: '15:00', id: 61, disabled: false},
  {value: '15:15', id: 62, disabled: false},
  {value: '15:30', id: 63, disabled: false},
  {value: '15:45', id: 64, disabled: false},
  {value: '16:00', id: 65, disabled: false},
  {value: '16:15', id: 66, disabled: false},
  {value: '16:30', id: 67, disabled: false},
  {value: '16:45', id: 68, disabled: false},
  {value: '17:00', id: 69, disabled: false},
  {value: '17:15', id: 70, disabled: false},
  {value: '17:30', id: 71, disabled: false},
  {value: '17:45', id: 72, disabled: false},
  {value: '18:00', id: 73, disabled: false},
  {value: '18:15', id: 74, disabled: false},
  {value: '18:30', id: 75, disabled: false},
  {value: '18:45', id: 76, disabled: false},
  {value: '19:00', id: 77, disabled: false},
  {value: '19:15', id: 78, disabled: false},
  {value: '19:30', id: 79, disabled: false},
  {value: '19:45', id: 80, disabled: false},
  {value: '20:00', id: 81, disabled: false},
  {value: '20:15', id: 82, disabled: false},
  {value: '20:30', id: 83, disabled: false},
  {value: '20:45', id: 84, disabled: false},
  {value: '21:00', id: 85, disabled: false},
  {value: '21:15', id: 86, disabled: false},
  {value: '21:30', id: 87, disabled: false},
  {value: '21:45', id: 88, disabled: false},
  {value: '22:00', id: 89, disabled: false},
  {value: '22:15', id: 90, disabled: false},
  {value: '22:30', id: 91, disabled: false},
  {value: '22:45', id: 92, disabled: false},
  {value: '23:00', id: 93, disabled: false},
  {value: '23:15', id: 94, disabled: false},
  {value: '23:30', id: 95, disabled: false},
  {value: '23:45', id: 96, disabled: false},
];

// export const clientPortalURL = `http://localhost:3001`; //local
// export const clientPortalURL = `https://vendordev.gudmed.in`; //ner
// export const clientPortalURL = `https://clitest.gudmed.in`; //amplify test
// export const clientPortalURL = `https://vendoruat.gudmed.in`; //uat
export const clientPortalURL = `https://client.gudmed.in`; //prod
