import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {matchRoutes} from 'react-router-config';
import qs from 'qs';
import AppContext from './AppContext';
import {useAuthToken} from './AppHooks';
import {Loader} from '../index';
import PropTypes from 'prop-types';
import {checkPermission} from './Utils';
import {
  initialUrl,
  initUrlProfile,
  clientPortalURL,
} from '../../shared/constants/AppConst';
import {resetState} from '../../shared/constants/ActionTypes';
import {AuthType} from '../../shared/constants/AppEnums';

import {
  setInitialPath,
  onCognitoUserSignOut,
  onJWTAuthSignout,
  onSignOutAuth0User,
  onSignOutFirebaseUser,
  onJwtSignIn,
} from '../../redux/actions';
import {PASSWORD_REQUEST_TAG} from '../../shared/constants/ActionTypes';
const crypto = require('crypto');
var algorithm = 'aes-256-ctr';
var password = 'd6F3Efeq';
const AuthRoutes = ({children}) => {
  const {pathname, search} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    routes,
    changeNavStyle,
    updateThemeStyle,
    updateThemeMode,
    setRTL,
  } = useContext(AppContext);

  const [loading, user] = useAuthToken();
  const initialPath = useSelector(({settings}) => settings.initialPath);
  const currentRoute = matchRoutes(routes, pathname)[0].route;
  const [fromclient, setfromclient] = useState(null);
  let isPermitted = checkPermission(
    currentRoute.auth,
    user ? user.role : null,
    user ? user.mrdDrcat : null,
  );

  //Decrypt password
  const decryptpassword = function ({data}) {
    var decipher = crypto.createDecipher(algorithm, password);
    var dec = decipher.update(data, 'hex', 'utf8');
    dec += decipher.final('utf8');
    return dec;
  };
  // In the second portal, listen for messages from the first portal
  useEffect(() => {
    // Wait until the second portal is fully loaded and ready
    window.addEventListener('load', () => {
      console.log('Second portal is fully loaded');
      if (window.opener && window.opener.postMessage) {
        try {
          // Notify the first portal that this window is ready to receive messages
          window.opener.postMessage({type: 'ready'}, clientPortalURL);
        } catch (error) {
          console.error('Error sending message to parent window:', error);
        }
      } else {
        localStorage.setItem('browserparam', '');
        console.log('No opener window found or postMessage is not available.');
      }
    });

    const handleMessage = (event) => {
      console.log('receive d');

      if (!event.origin.includes(clientPortalURL)) return;
      localStorage.clear();
      dispatch(resetState());
      if (event.origin.includes(clientPortalURL)) {
        // Check the message type and handle accordingly
        if (event.data.type === 'clearData') {
          setfromclient('clearData');

          window.location.search = '';

          localStorage.setItem('browserparam', '');

          // onDeny(false);
          localStorage.clear();
          dispatch(resetState());

          if (user && user.authType === AuthType.AWS_COGNITO) {
            dispatch(onCognitoUserSignOut());
          } else if (user && user.authType === AuthType.FIREBASE) {
            dispatch(onSignOutFirebaseUser());
          } else if (user && user.authType === AuthType.AUTH0) {
            dispatch(onSignOutAuth0User());
          } else if (user && user.authType === AuthType.JWT_AUTH) {
            let pushdata = {
              userId: user.userId,
              userCode: user.doctorCode,
              onlineStatus: 'Logout',
              loginType: 'doctor',
            };

            dispatch(onJWTAuthSignout(pushdata));
          }
          // logoutUser();
          // You can store the data in localStorage or use it directly
        } else if (event.data.type == 'setData') {
          localStorage.clear();

          dispatch(resetState());
          setfromclient('setData');
          console.log(event.data.data, 'setDatasd');

          let paramValue = null;
          let doctorParam = null;

          // if (urlParams.size > 0) {
          if (Object.values(event.data.data).length > 0) {
            paramValue = event.data.data;

            doctorParam = paramValue;
            // sessionStorage.setItem('browserparam', JSON.stringify(doctorParam))
            localStorage.setItem('browserparam', JSON.stringify(doctorParam));
          }
          dispatch(
            onJwtSignIn({
              mobileNbr: doctorParam.mobileNo,
              paswd: decryptpassword({data: doctorParam.paswd}),
            }),
            history,
          );
        }
      }
    };

    window.addEventListener('message', handleMessage);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  useEffect(() => {
    function setInitPath() {
      if (
        initialPath &&
        initialPath.includes('requestPassword') &&
        initialPath.includes('/')
      ) {
        let data = initialPath.split('/')[2];
        dispatch({
          type: PASSWORD_REQUEST_TAG,
          payload: data,
        });
      }
      if (
        initialPath === '/' &&
        [
          '/signin',
          '/signup',
          '/confirm-signup',
          '/reset-password',
          '/error-pages/error-404',
          '/error-pages/error-401',
          '/forget-password',
          '/set-password',
          '/requestPassword',
        ].indexOf(pathname) === -1
      ) {
        if (isPermitted) {
          console.log(1);
          dispatch(setInitialPath(pathname));
        } else {
          console.log(2);
          dispatch(setInitialPath(undefined));
        }
      }
    }

    setInitPath();
  }, [dispatch, isPermitted, initialPath, pathname, user]);

  useEffect(() => {
    function handleQueryParams() {
      const query = qs.parse(search.split('?')[1]);
      if (query.layout) {
        changeNavStyle(query.layout);
      }
      if (query.mode) {
        updateThemeMode(query.mode);
      }
      if (query.rtl) {
        setRTL(true);
      }
      if (query.style) {
        updateThemeStyle(query.style);
      }
    }

    if (search) {
      handleQueryParams();
    }
  }, [changeNavStyle, updateThemeStyle, updateThemeMode, setRTL, search]);

  useEffect(() => {
    if (!loading) {
      let paramValue = localStorage.getItem('browserparam');
      let paramcond = '';
      // if (paramValue) {
      //   paramcond = `?param=${paramValue}`;
      // }
      // else
      // {
      //   paramcond =''
      // }

      if (!user && !isPermitted) {
        history.push('/signin'); // allowed route
      } else if (user && !isPermitted) {
        history.push('/error-pages/error-401'); // Not found
      } else if (user && isPermitted) {
        if (
          pathname === '/' ||
          pathname === '/signin' ||
          pathname === '/signup'
        ) {
          if (user !== null && user.doctorstatus === 7) {
            history.push(`${initialUrl}${paramcond}`);
          } else {
            history.push(`${initUrlProfile}${paramcond}`);
          }
        } else if (
          initialPath &&
          initialUrl !== initialPath &&
          (initialPath !== '/' ||
            initialPath !== '/signin' ||
            initialPath !== '/signup')
        ) {
          if (pathname.includes('/requestPassword')) {
            history.push(`${pathname}${paramcond}`);
          } else if (pathname.includes('/MTPreviewChanges')) {
            history.push(`${pathname}${paramcond}`);
          } else if (user !== null && user.doctorstatus === 7 && pathname) {
            history.push(`${pathname}${paramcond}`);
          } else if (user !== null && user.doctorstatus === 7 && !pathname) {
            history.push(`${initialUrl}${paramcond}`);
          } else {
            history.push(`${initUrlProfile}${paramcond}`);
          }
        }
      }
    }
  }, [user, loading, initialPath, isPermitted, pathname, history]);

  return loading ? <Loader /> : <>{children}</>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};
