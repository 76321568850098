import React from 'react';
import HorizontalGroup from './HorizontalGroup';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalItem from './HorizontalItem';
import HorizontalSingle from './HorizontalSingle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import {useSelector} from 'react-redux';

import routesConfig from '../../../../modules/routesConfig';
import Box from '@material-ui/core/Box';

const HorizontalNav = () => {
  const LoginUser = useSelector(({auth}) => auth.user);
  return (
    <List className='navbarNav'>
      {routesConfig.map(
        (item) => (
          console.log(LoginUser, 'LoginUser.'),
          console.log(item.auth, 'item.auth.'),
          (
            <React.Fragment key={item.id}>
              {item.type === 'single' &&
                (item.auth.includes(Number(LoginUser.roleId)) ||
                  item.auth.includes(Number(LoginUser.mrdDrCheck)) ||
                  item.auth.includes(LoginUser.dischargeSummary)) && (
                  <HorizontalSingle item={item} nestedLevel={0} />
                )}

              {item.type === 'group' &&
                item.auth.includes(LoginUser.roleId) && (
                  <HorizontalGroup item={item} nestedLevel={0} />
                )}

              {item.type === 'collapse' && (
                <HorizontalCollapse item={item} nestedLevel={0} />
              )}

              {item.type === 'item' && (
                <HorizontalItem item={item} nestedLevel={0} />
              )}

              {item.type === 'divider' && (
                <Box my={5} clone>
                  <Divider />
                </Box>
              )}
            </React.Fragment>
          )
        ),
      )}
    </List>
  );
};

export default HorizontalNav;
