/************
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : Route page for signup
 * Dependicies : To use @material-ui/core,react-router-dom,formik
 ************/
import React from 'react';
import {authRole} from '../../shared/constants/AppConst';

export const MTChangesConfig = [
  {
    routes: [
      {
        path: '/MTPreviewChanges',
        component: React.lazy(() => import('./MTPreviewPage')),
      },
    ],
  },
];
