/************
 * Developed by : Shiva Software Solutions
 * Date : 22-06-2021
 * Descriptions : User Mangament api calling
 ************/
import React from 'react';
import {
  FETCH_ERROR,
  SHOW_MESSAGE,
  FETCH_START,
  FETCH_SUCCESS,
  DISCHARGE_LIST,
  DISCHARGE_COUNT,
  APPROVE_MSG,
  APPROVE_STATUS,
} from '../../shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
import IntlMessages from '../../@crema/utility/IntlMessages';

//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'DischargeSummary.js';

// Reset User Password
export const getDischargeList = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call Reset User Password   - get Token
    try {
      jwtAxios
        .post('v1/disChargeRoutes/getDoctorDischargeListJwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call Reset User Password  - Reset User Password   using token

            let bodytoken = {jwtToken: data.data.body['token']};
            jwtAxios
              .post('v1/disChargeRoutes/getDoctorDischargeList', bodytoken)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});

                  dispatch({
                    type: DISCHARGE_LIST,
                    payload: data?.data?.body?.list,
                  });
                  dispatch({
                    type: DISCHARGE_COUNT,
                    payload: parseInt(data?.data?.body?.count),
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getDoctorDischargeList',
                    '0',
                    error,
                  ),
                );
              });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getDoctorDischargeListJwt',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getDischargeList',
          '0',
          err,
        ),
      );
    }
  };
};

// Reset User Password
export const ApproveDischargeSummary = (value) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});

    //Call Reset User Password   - get Token
    try {
      jwtAxios
        .post('v1/disChargeRoutes/approveDischargeSummaryJwt', value)
        .then((data) => {
          if (data.status === 200) {
            //Call Reset User Password  - Reset User Password   using token

            let bodytoken = {jwtToken: data.data.body['token']};
            jwtAxios
              .post('v1/disChargeRoutes/approveDischargeSummary', bodytoken)
              .then((data) => {
                if (data.status === 200) {
                  dispatch({type: FETCH_SUCCESS});
                  dispatch({
                    type: APPROVE_MSG,
                    payload: data.data.body.status,
                  });
                  dispatch({
                    type: APPROVE_STATUS,
                    payload: data.data.body.dischargeStatus,
                  });
                } else {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: <IntlMessages id='message.somethingWentWrong' />,
                  });
                }
              })
              .catch((error) => {
                dispatch({type: FETCH_ERROR, payload: error.message});
                dispatch(
                  onWriteCloudWatchLog(
                    streamname,
                    filename,
                    'getDoctorDischargeList',
                    '0',
                    error,
                  ),
                );
              });
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: <IntlMessages id='message.somethingWentWrong' />,
            });
          }
        })
        .catch((error) => {
          dispatch({type: FETCH_ERROR, payload: error.message});
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'getDoctorDischargeListJwt',
              '0',
              error,
            ),
          );
        });
    } catch (err) {
      dispatch(fetchError(err.response.message));
      dispatch(
        onWriteCloudWatchLog(
          streamname,
          filename,
          'getDischargeList',
          '0',
          err,
        ),
      );
    }
  };
};
