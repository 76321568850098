/************
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 ************/
import {
  DISCHARGE_LIST,
  DISCHARGE_COUNT,
  RESET_STATE,
  APPROVE_STATUS,
  APPROVE_MSG,
} from '../../shared/constants/ActionTypes';

const initialState = {
  dischargeList: [],
  dischargeCount: 0,
  approveStatus: 0,
  approveMessage: '',
};

const dischargeSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {...initialState}; // Reset the state to the initial state
    //Assign dischargelist
    case DISCHARGE_LIST:
      return {
        ...state,
        dischargeList: action.payload,
      };
    //Assign dischargelist
    case DISCHARGE_COUNT:
      return {
        ...state,
        dischargeCount: action.payload,
      };
    case APPROVE_STATUS:
      return {
        ...state,
        approveStatus: action.payload,
      };
    case APPROVE_MSG:
      return {
        ...state,
        approveMessage: action.payload,
      };

    default:
      return state;
  }
};
export default dischargeSummaryReducer;
