import React, {useContext, useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import {useAuthUser} from '../../utility/AppHooks';
import AppContext from '../../utility/AppContext';
import {
  Divider,
  makeStyles,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Card,
  Drawer,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import {orange} from '@material-ui/core/colors';
import {Fonts} from '../../../shared/constants/AppEnums';
import {SELECTED_NOTIFICATION} from '../../../shared/constants/ActionTypes';
import Hidden from '@material-ui/core/Hidden';
import ChangePasswordPopup from './ChangepasswordPopup';
// import confirmation dialog
import ConfirmationDialog from './confirmationDialog';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  storeOnlineOfflineStatus,
  CallNotifications,
} from '../../../redux/actions';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import jwtAxios from '../../services/auth/jwt-auth/jwt-api';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// for style
const useStyles = makeStyles((theme) => {
  return {
    userRoot: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      position: 'relative',
    },
    btnblue: {
      background: '#2e4167',
      color: '#ffffff',
      textTransform: 'capitalize',
      '&:hover': {
        background: '#2e4167',
        color: '#ffffff',
      },
    },
    btnred: {
      background: '#ff7252',
      color: '#ffffff',
      textTransform: 'capitalize',
      '&:hover': {
        background: '#ff7252',
        color: '#ffffff',
      },
    },
    avatar: {
      fontSize: 24,
      backgroundColor: orange[500],
    },
    autowidht: {
      width: '40px',
      height: '40px',
      marginLeft: 6,
      borderRadius: '60px',
      border: '1px solid #2e4167',
    },
    userInfo: {
      width: 'calc(100% - 75px)',
    },
    pointer: {
      cursor: 'pointer',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontSize: 15,
      fontWeight: Fonts.MEDIUM,
      color: (props) =>
        props.bgType === 'colored' ? 'white' : theme.palette.text.primary,
    },

    outerBox: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
    },
    onlineStatus: {
      position: 'absolute',
      zIndex: 99,

      color: '#21d221',
      width: 18,
      height: 18,
      top: '-7%',
      left: '2%',
    },
    offlineStatus: {
      position: 'absolute',
      zIndex: 99,

      color: '#ff0000',
      width: 18,
      height: 18,
      top: '-7%',
      left: '2%',
    },
    bgColor: {
      color: (props) =>
        props.bgType === 'colored' ? 'white' : theme.palette.text.primary,
    },
    menuitems: {
      '& .MuiMenu-paper': {
        marginTop: '1.8%',
      },
    },
    mar3: {
      marginRight: '12px',
    },
  };
});

const HorUserInfo = ({bgType = 'colored'}) => {
  const [changePassword, setChangePasswordPopup] = useState(false);
  const {themeMode} = useContext(AppContext);
  const [openpopup, setopenpopup] = useState(false);
  const user = useAuthUser();
  const dispatch = useDispatch();
  const mt_notifications = useSelector(
    ({dashboard}) => dashboard.MTnotifications,
  );
  const history = useHistory();

  //get the values from routings
  const location = useLocation();
  const [openNoti, setopenNoti] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [getStatus, setgetStatus] = React.useState('Online');
  const [interVel, setInterVel] = useState(null);
  const [popup,setpopup]=useState(false)
  const [tempVal,setTempVal]=useState('')
  const [notificationBadgeCount, setNotificationBadgeCount] = useState(
    mt_notifications?.length ? mt_notifications?.length : 0,
  );
  const [statusChange, setStatusChange] = React.useState({
    onlineshow: {display: 'block'},
    onlinehide: {display: 'none'},
    offlineshow: {display: 'block'},
    offlinehide: {display: 'none'},
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // changepassword open popup event
  const openChangepasswordPopup = () => {
    setAnchorEl(null);
    setChangePasswordPopup(true);
  };

  // logout open popup event
  const logoutClick = () => {
    setAnchorEl(null);
    setopenpopup(true);
  };
  const onGoToMyAccount = () => {
    setAnchorEl(null);
    history.push('/my-profile');
  };
  const onGoToMyPreferences = () => {
    setAnchorEl(null);
    history.push('/my-preferences');
  };
  // online/offline change event
  const statusChanges = (val) => {
    console.log(user, 'userdata');
    let pushdata = {
      userId: user.id,
      userCode: user.doctorCode,
      onlineStatus: val,
      loginType: 'doctor',
    };
    dispatch(storeOnlineOfflineStatus(pushdata));
    setgetStatus(val);
    setAnchorEl(null);
  };

  useEffect(() => {
    doctorMTNotification();
    const timer = setInterval(() => {
      doctorMTNotification();
      if (interVel) clearInterval(interVel);
    }, 1 * 60 * 1000);

    setInterVel(timer);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const doctorMTNotification = () => {
    let pushdata = {
      clientId: 0,
      credentialId: user.doctorCode,
      userType: 'Dr',
    };
    dispatch(CallNotifications(pushdata));
  };

  // for user click
  const handleNotificationClick = (event) => {
    if (mt_notifications?.length > 0) {
      // dispatch({
      //   type: MRD_OPEN_NOTIFI,
      //   payload: !openNoti,
      // });
      setopenNoti(!openNoti);
    }
  };

  const classes = useStyles({themeMode, bgType});
  async function pickNotificaiton(item) {
    setTempVal(item)
    // Get the current route path
    const currentPath = location.pathname;
    if(currentPath=='/MTPreviewChanges')
    {
      setpopup(true)
    }
    else{
      if (item?.mrdId) {
        let text = item?.patientName
          ? item?.patientName
          : item?.opId
          ? item?.opId
          : '';
        dispatch({type: SELECTED_NOTIFICATION, payload: text});
        await changeSts(item);
        setTimeout(() => {
          history.push('/dischargeSummary');
        }, 1000);
        // setSearchText(text);
        // await callSearch(text);
      }
      setTempVal('')
    }
    
  }
  async function proceedaction(){
    let item=tempVal;
    if (item?.mrdId) {
      let text = item?.patientName
        ? item?.patientName
        : item?.opId
        ? item?.opId
        : '';
      dispatch({type: SELECTED_NOTIFICATION, payload: text});
      await changeSts(item);
      setTimeout(() => {
        history.push('/dischargeSummary');
      }, 1000);
      setTempVal('')
      // setSearchText(text);
      // await callSearch(text);
    }

  }
  async function changeSts(rowData) {
    (async () => {
      const body = {
        mrdId: rowData?.mrdId,
      };
      await jwtAxios
        .post('v1/disChargeRoutes/changeNotificationSts', body)
        .then((data) => {
          if (data.status === 200) {
            setopenNoti(!openNoti);
            let pushdata = {
              clientId: 0,
              credentialId: user && user.doctorCode ? user.doctorCode : 0,
              userType: 'Dr',
            };
            dispatch(CallNotifications(pushdata));
            return;
          }
        });
    })();
  }
  const toggleDrawer = () => {
    setopenNoti(!openNoti);
  };
  async function closeNotification(item) {
    if (item?.mrdId) {
      await changeSts(item);
    }
  }
  const list = () => (
    <Box sx={{width: '300px'}} role='presentation'>
      <Grid container style={{backgroundColor: '#2e4167'}}>
        <Grid xs={10}>
          <ListItem key={'Notifications'}>
            <ListItemIcon className={classes.clockicon}>
              <NotificationsIcon style={{color: '#ffff'}} />
            </ListItemIcon>
            <ListItemText
              primary={'Notifications'}
              primaryTypographyProps={{
                style: {fontWeight: 'bold', color: '#fff'},
              }}
            />
          </ListItem>
        </Grid>
        <Grid xs={2}>
          <IconButton
            onClick={() => toggleDrawer()}
            style={{padding: '0', paddingLeft: '10px'}}>
            <HighlightOffIcon className={classes.closeBtnColor} />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />
      {mt_notifications.length > 0 ? (
        <List style={{overflow: 'auto', width: '98%', paddingLeft: '2%'}}>
          {mt_notifications.map((text, index) => (
            <Card
              key={index}
              className={classes.cardStyle}
              style={{
                background: text?.messageType == 4 ? '#90ee90ab' : '#e4d00a80',
                cursor: 'pointer',
              }}
              variant='outlined'>
              <Grid container spacing={4}>
                {/* Icon Section */}

                {/* Message Section */}
                <Grid item xs={11} onClick={() => pickNotificaiton(text)}>
                  <Typography
                    style={{
                      color: '#333',
                      fontSize: '13px',
                      //  fontWeight: "bold",
                      //  marginLeft: "8px",
                    }}>
                    {text.message}
                  </Typography>
                </Grid>

                {/* Close Button */}
                <Grid
                  item
                  xs={1}
                  style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <IconButton
                    size='small'
                    onClick={() => closeNotification(text)}
                    style={{color: '#888', '&:hover': {color: '#d32f2f'}}}>
                    <HighlightOffIcon fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
            </Card>
          ))}
        </List>
      ) : (
        <Grid container>
          <Grid item={true} xs={12} sm={12} style={{padding: '28%'}}>
            <p>No records found</p>
          </Grid>
        </Grid>
      )}
    </Box>
  );
  return (
    <Box py={2} pl={{xs: 2, sm: 3, md: 5}}>
      <Box className={classes.outerBox}>
        <Box
          className={classes.userRoot}
          display='flex'
          onClick={handleNotificationClick}>
          <Badge
            className={`${classes.badgeStyle} ${classes.mar3}`}
            badgeContent={
              mt_notifications?.length > 0 ? mt_notifications?.length : 0
            }
            color='secondary'>
            <NotificationsIcon className={classes.bgColor} />
          </Badge>
        </Box>
        <Box className={classes.userRoot} display='flex' onClick={handleClick}>
          {/* {user.photoURL ? (
            <Avatar className={classes.avatar} src={user.photoURL} />
          ) : ( 
            <Avatar className={classes.avatar}>{getUserAvatar()}</Avatar>
          )} */}

          {/* <img
              src={user.logo ? user.logo : '/assets/images/profile-user.png'}
              className={classes.autowidht}
            /> */}
          <Avatar
            src='/assets/images/profile-user.png'
            className={classes.autowidht}
          />
          {getStatus === 'Online' ? (
            <FiberManualRecordIcon className={classes.onlineStatus} />
          ) : (
            <FiberManualRecordIcon className={classes.offlineStatus} />
          )}

          <Hidden mdDown>
            <Box ml={3} className={classes.userName}>
              {/* display name check */}
              {user.displayName ? user.displayName : user.email}
              {/* <Box fontSize={13} fontWeight={Fonts.LIGHT}>
                  {user.role}
                </Box> */}
            </Box>
          </Hidden>
        </Box>
      </Box>
      <Box className={classes.userInfo}>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          <MenuItem
            onClick={() => statusChanges('Online')}
            style={
              getStatus === 'Online'
                ? statusChange.onlinehide
                : statusChange.onlineshow
            }>
            {' '}
            <FiberManualRecordIcon className={classes.onlineStatus} />
            Online
          </MenuItem>
          <MenuItem
            onClick={() => statusChanges('Offline')}
            style={
              getStatus === 'Offline'
                ? statusChange.offlinehide
                : statusChange.offlineshow
            }>
            {' '}
            <FiberManualRecordIcon className={classes.offlineStatus} />
            Offline
          </MenuItem>
          <Divider></Divider>
          <MenuItem onClose={handleClose} onClick={() => onGoToMyAccount()}>
            My Profile
          </MenuItem>
          <MenuItem onClose={handleClose} onClick={() => onGoToMyPreferences()}>
            My Preferences
          </MenuItem>
          <MenuItem
            onClose={handleClose}
            onClick={() => openChangepasswordPopup()}>
            Change Password
          </MenuItem>

          <MenuItem
            onClick={() => {
              logoutClick();
            }}>
            Logout
          </MenuItem>
        </Menu>
        <ChangePasswordPopup
          open={changePassword}
          onDeny={setChangePasswordPopup}
          title='Change Password'
          //  dialogTitle={<IntlMessages id='Change Password' />}
        />
        <ConfirmationDialog opendialog={openpopup} onDeny={setopenpopup} />
      </Box>
      {openNoti ? (
        <Drawer
          transitionDuration={{enter: 500, exit: 1000}}
          anchor='right'
          open={openNoti}
          width='20%'
          onClose={() => toggleDrawer()}
          className={classes.drawerr}>
          {list()}
        </Drawer>
      ) : null}
       <Dialog open={popup} className={classes.dialogStyle} disableBackdropClick>
                  <DialogTitle id='customized-dialog-title'>Confirmation</DialogTitle>
                  <DialogContent>
                    <Typography
                      gutterBottom
                      component='h4'
                      style={{fontWeight: 600, color: '#000'}}>
                      This action will take you to the dashboard, and your current work won’t be saved. Are you sure you want to continue?
                    </Typography>
                  </DialogContent>
                  <DialogActions className={classes.dialogactionsclass}>
                    <Button onClick={() => setpopup(false)} className={classes.btnred}>
                      No
                    </Button>
                    <Button onClick={() => {
                      setpopup(false)
                      proceedaction()}} className={classes.btnblue}>
                      Yes
                    </Button>
                  </DialogActions>
                </Dialog>
    </Box>
  );
};

export default HorUserInfo;
