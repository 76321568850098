/************

 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Define reducers

 ************/
import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import Dashboard from './Dashboard';
import UserManageData from './userManagement';
import Auth from './Auth';
import Patient from './Patient';
import profileReducer from './myProfile';
import mrdUserReducer from './Mrduser';
import dischargeSummaryReducer from './DischargeSummary';

const reducers = (history) => {
  return (state, action) => {
    // If the RESET_STATE action is dispatched, reset all reducers to their initial state
    if (action.type === 'RESET_STATE') {
      state = undefined; // This will reset the entire Redux state
    }

    return combineReducers({
      router: connectRouter(history),
      settings: Settings,
      auth: Auth,
      dashboard: Dashboard,
      userManage: UserManageData,
      common: Common,
      patient: Patient,
      profile: profileReducer,
      mrdDr: mrdUserReducer,
      discharge: dischargeSummaryReducer,
    })(state, action);
  };
};
export default reducers;
